import React from 'react'
import Layout from 'src/components/layout/layout'
import OAuthCallback from 'src/components/oauth-callback/oauth-callback'

const OAuthCallbackPage = () => {
    return (
        <Layout nav={false}>
          <OAuthCallback />
        </Layout>
      )

}

export default OAuthCallbackPage
